<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col class="mb-3" cols="12">
                    <b-form-file v-show="false" @input="newFile" v-model="files" multiple :placeholder="$t('select_file')"
                        ref="documentStorageNewFile"></b-form-file>
                    <b-button variant="primary" @click="$refs.documentStorageNewFile.$el.childNodes[0].click()">
                        {{ $t('file_upload') }}
                    </b-button>
                </b-col>
                <b-col class="mb-3" cols="12" md="6" xl="4" v-for="(file, index) in innerValue.files" :key="index">
                    <b-card header-tag="header" footer-tag="footer" class="document-storage-file-card">
                        <template #header>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center">
                                    <h6 class="mb-0">{{ file.name }}</h6>
                                </div>
                                <span class="h3" @click="removeFile(file)">
                                    <b-icon class="border border-danger rounded " icon="trash-fill"
                                        variant="danger"></b-icon>
                                </span>
                            </div>
                        </template>
                        <b-card-text>
                            <p class="mb-1">{{ $t('file_extention') }}: {{ fileExtention(file.file_name) }}</p>
                            <p class="mb-1">{{ $t('created_at') }}: {{ $moment(file.created_at).format("DD-MM-YYYY HH:mm")
                            }}</p>
                        </b-card-text>

                        <template #footer>
                            <b-button class="float-right" variant="primary" @click="download(file)">{{ $t('downloand')
                            }}</b-button>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
//Service
import DocumentStorageService from "@/services/DocumentStorageService";

export default {
    name: "DocumentStorageDocuments",
    props: {
        formId: {
            type: Number,
        },
        value: {
            type: Object
        },
    },
    data() {
        return {
            files: [],
            form: {
                code: null,
                name: null,
            }
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
                return val
            },
        },
        buttonText() {
            return this.$t('close')
        },
        fileNames() {
            if (this.innerValue.files?.length > 0) {
                return this.innerValue.files.map(item => {
                    return item.name
                })
            }
            return []
        },
    },
    watch: {
        formId: {
            handler(val) {
                if (val > -1) {
                    this.get(val)
                }
            },
            immediate: true
        }
    },
    methods: {
        fileExtention(name) {
            const lastDot = name.split('.').pop();
            return lastDot.replace(".")
        },
        async removeFile(file) {
            const res = await this.$swal({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
            if (res.isConfirmed == false) {
                return
            }

            const response = await DocumentStorageService.deleteDocument(file.uuid).catch(e => this.$toast.error(this.$t("api." + e.data.message)))

            if (response.data.success) {
                this.get(this.formId)
            }
            this.$toast.success(this.$t("api." + response.data.message))
        },
        async newFile(files) {

            const formData = new FormData();
            files.forEach((itm, index) => {
                formData.append("files[" + index + "]", itm);
            })
            formData.append('document_id', this.innerValue.id);

            const response = await DocumentStorageService.addMedia(formData).catch(e => this.$toast.error(this.$t("api." + e.data.message)))
            if (response.data.success) {
                this.get(this.formId)
            }
            this.$toast.success(this.$t("api." + response.data.message))
        },
        async download(file) {
            const response = await DocumentStorageService.documentDownload(file.uuid).catch(e => this.$toast.error(this.$t("api." + e.data.message)))
            if (response.status === 200) {
                this._downloadFile(response, file.file_name)
            }
            this.$toast.success(this.$t("downloaded"))
        },
        get(id) {
            DocumentStorageService.get(id)
                .then((response) => {
                    this.innerValue = response.data.data;
                })
                .catch((error) => {
                    this.showErrors(error)
                })
        },
        formClear() {
            this.innerValue = {}
        }
    }
}
</script>


<style lang="scss">
.document-storage-file-card {
    .card-body {
        padding: 0.6rem 0.8rem;
    }

    .card-header {
        padding: 0.4rem 0.8rem;

        span {
            margin-bottom: 0;

            svg {
                padding: 0.16rem;
                font-size: 1.8rem;
            }

            transition: all 0.15s linear;

            &:active {
                transform: translateY(4px);
            }
        }
    }
}
</style>